import React, { useEffect } from 'react'
import { Box, Flex, HStack, Stack, Text, VStack } from '@chakra-ui/layout'
import { useForm } from 'react-hook-form'
import { Button } from '@chakra-ui/button'
import { Input } from '@chakra-ui/input'
import { FormErrorMessage } from '@chakra-ui/form-control'
import { Radio, RadioGroup } from '@chakra-ui/radio'
import { Textarea } from '@chakra-ui/textarea'
import { Select } from '@chakra-ui/select'
import { Alert, AlertIcon } from '@chakra-ui/alert'
import { Link } from 'gatsby'
import { Container } from '@/components/ui-components'
import { useAuth } from '@/contexts/AuthContext'
import { FormElement, FormSection } from '@/components/forms'
import ApiFetch from '@/utils/api'
import { DeleteIcon } from '@chakra-ui/icons'
import AverageCallInfoModal from '../components/average-call-info-modal'

const FormSectionTitle = ({ title, subtitle }) => (
  <Box mb={7}>
    <Box className="mb-2 font-bold font-heading">{title}</Box>
    <Box className="text-sm">{subtitle}</Box>
  </Box>
)

const UserInfoBanner = ({ user }) => {
  return (
    <Stack
      direction={{ base: 'column', sm: 'row' }}
      justify="space-between"
      className="text-white bg-darkBlue p-7"
    >
      <Box
        className="mb-4 font-bold font-heading text-md sm:mb-0 "
        sx={{ p: { mb: 0 } }}
      >
        <Text>Subscriber Number: {user?.shopData?.sub_num}</Text>
        <Text>Shop Name: {user?.shopData?.shop_name}</Text>
      </Box>
      <Stack direction={{ base: 'column', lg: 'row' }}>
        <Box maxW="250px" className="text-sm" mb={[4, 0]}>
          If this is a callback on a previous call, please use the call history.
        </Box>
        <Button as={Link} variant="lightBlue" to="/call-history">
          VIEW CALL HISTORY
        </Button>
      </Stack>
    </Stack>
  )
}

const RecyclerTechCallScreen = () => {
  const { currentUser } = useAuth()
  const { handleSubmit, register, setValue, errors, watch, reset } = useForm({
    defaultValue: {
      techAvailability: 'any',
    },
  })
  const [formStatus, setFormStatus] = React.useState('idle')
  const [techniciansAvailable, setTechniciansAvailable] = React.useState([])

  // ñapa from old implementation
  const initialCodes = React.useMemo(
    () => [
      {
        id: 'first',
        key: '',
        value: '',
      },
    ],
    []
  )
  const [codes, setCodes] = React.useState(initialCodes)

  const [error, setError] = React.useState(null)
  const [averageCallInfo, setAverageCallInfo] = React.useState(null)

  const onSubmit = async (values) => {
    setFormStatus('submitting')
    error && setError(null)
    !values.atsgtechreq && (values.atsgtechreq = 'any')
    const formData = values
    formData.codes = codes.map((code) => ({
      value: code.value,
      key: code.key,
    }))
    formData.shop_name = currentUser.shopData.shop_name
    formData.sub_num = currentUser.shopData.sub_num

    // Parse contact methods
    if (formData.contactMethod === 'callDefaultShop') {
      formData.contactMethodValue = currentUser?.shopData?.shopPhone
    } else if (formData.contactMethod === 'emailPerson') {
      formData.contactMethodValue = currentUser?.shopData?.userEmail
    } else {
      formData.contactMethodValue = formData.contactMethod
      formData.contactMethod = 'other'
    }

    try {
      const response = await ApiFetch({
        method: 'POST',
        path: `/salvage/tech-call`,
        body: formData,
        userToken: currentUser?.shopData?.idToken,
      })

      if (response.code === 200) {
        setFormStatus('submitted')
        // navigate('/call-history')
        setAverageCallInfo({
          average: response.result.average,
          longestCall: response.result.longestCall,
        })
      } else if (response.errors && response.errors.length) {
        // TODO: could be validation errors here
        setError(response.errors)
      }
    } catch (error) {
      setError(error.message)
      setFormStatus('error')
    }
  }

  useEffect(() => {
    register('contactMethod', { required: true })
  }, [])

  const watchContactMethods = watch('contactMethod')

  const getAvailableTechnicians = async () => {
    try {
      const response = await ApiFetch({
        path: `/staff/available`,
      })
      if (response.data) {
        const _techs = response.data.rows.sort((tech1, tech2) =>
          tech1.name.localeCompare(tech2.name)
        )
        setTechniciansAvailable(_techs)
      }
    } catch {
      setTechniciansAvailable([])
    }
  }
  useEffect(() => {
    getAvailableTechnicians()
  }, [])

  const onHandleCodeSelectChange = (i, event) => {
    event.preventDefault()
    let _codes = [...codes]
    _codes.map((code) => {
      if (code.id === i) {
        code.key = event.target.value
        if (code.key === '') {
          code.value = ''
        }
      }
      return code
    })
    setCodes(_codes)
  }

  const onRemoveCodeInput = (id, event) => {
    event.preventDefault()
    setCodes(codes.filter((code) => code.id !== id))
  }

  const onHandleCodeInputChange = (i, event) => {
    event.preventDefault()
    let _codes = [...codes]
    _codes.map((code) => {
      if (code.id === i) {
        code.value = event.target.value
      }
      return code
    })
    setCodes(_codes)
  }

  const onAddCodeInput = (e) => {
    e.preventDefault()
    setCodes((codes) => [
      ...codes,
      { id: Date.now(), key: 'option1', value: '' },
    ])
  }

  const renderCodesUI = (props) => {
    return codes.map((code) => (
      <HStack mb={2} {...props} key={code.id}>
        <Select
          layerStyle="input"
          maxW="180px"
          value={code?.key ?? 'noObd2'}
          onChange={(e) => onHandleCodeSelectChange(code.id, e)}
          placeholder="Select Type"
          name="codes"
          ref={register}
        >
          <option value="noObd2">Not OBDII</option>
          <option value="pCodes">P-codes</option>
          <option value="bCodes">B-codes</option>
          <option value="cCodes">C-codes</option>
          <option value="uCodes">U-codes</option>
        </Select>
        {code.id !== 'first' && (
          <DeleteIcon
            cursor="pointer"
            onClick={(e) => onRemoveCodeInput(code.id, e)}
          />
        )}
        <Input
          id={code.id}
          placeholder="Code Number"
          maxW="270px"
          className="js-code-number"
          value={code.value && code.key !== '' ? code.value : ''}
          onChange={(e) => onHandleCodeInputChange(code.id, e)}
          disabled={
            !codes ||
            !codes.length ||
            (codes.length === 1 && codes[0].key === '')
          }
        />
      </HStack>
    ))
  }

  return (
    <Container>
      {/* <h1>Recyclers</h1> */}
      <FormSectionTitle
        title="Subscriber and Contact Information:"
        subtitle="Please make sure this is the correct shop, then fill in the information."
      />
      <Box>
        <UserInfoBanner user={currentUser} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {/*
           *
           * Contact Form Box
           *
           */}
          <FormSection>
            {/* 1st */}
            <FormElement
              id="targetShopName"
              label="Enter name of the target shop"
              type="input"
            >
              <Input
                placeholder="Target Shop Name"
                ref={register}
                name="targetShopName"
              />
            </FormElement>

            {/* 2nd */}
            <FormElement
              id="targetShopPhone"
              label="Enter phone number of the target shop"
              type="input"
              required={true}
              isInvalid={errors?.email}
            >
              <Input
                placeholder="Target Shop Phone"
                errorBorderColor="red"
                name="targetShopPhone"
                ref={register({ required: 'Shop phone is required' })}
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.email?.targetShopPhone}
              </FormErrorMessage>
            </FormElement>

            {/* 3rd */}
            <FormElement
              id="technam"
              label={'Who should we ask for at the target shop?'}
              type="input"
            >
              <Input placeholder="Person Name" name="technam" ref={register} />
            </FormElement>

            {/* 4th */}
            <FormElement
              id="invoice"
              label={'Enter invoice or claim number'}
              type="input"
            >
              <Input
                placeholder="Invoice or Claim Number"
                name="invoice"
                ref={register}
              />
            </FormElement>

            {/* 5th */}
            {/* "Contact info" for the recycler yard -> “Recycler Contact” in the comments section */}
            <FormElement
              id="contactMethod"
              label="How would you like to be contacted:"
              required={watchContactMethods === 'callRecyclerShop'}
              isInvalid={errors?.contactMethod}
            >
              <RadioGroup
                defaultValue={'callDefaultShop'}
                name="contactMethod"
                onChange={(value) => {
                  setValue('contactMethod', value)
                }}
              >
                <VStack align="flex-start" spacing={4}>
                  <Radio
                    value="callDefaultShop"
                    name="contactMethod"
                    defaultChecked
                    ref={register}
                  >
                    Phone: {currentUser?.shopData?.shopPhone}
                  </Radio>
                  <Radio
                    value="emailPerson"
                    name="contactMethod"
                    ref={register}
                  >
                    E-Mail: {currentUser?.shopData?.userEmail}
                  </Radio>
                  <>
                    <Radio
                      value="callRecyclerShop"
                      name="contactMethod"
                      ref={register}
                    >
                      Other:
                    </Radio>
                    <Input
                      placeholder="Enter Contact Information"
                      name="diffphone"
                      // ref={register}
                      ref={register(
                        watchContactMethods === 'contactMethod'
                          ? { required: 'This field is required' }
                          : null
                      )}
                    />
                  </>
                </VStack>
              </RadioGroup>
            </FormElement>

            {/* 6th */}
            {/* 
            It’s the email of the recycler that is our customer, but specifically where they want the claims form sent and it may differ from the email address on their  
            - Goes into DB "Claims Email" and should go into the "comments" section?
            */}
            <FormElement
              id="claimsEmail" // emailShop
              label={'Send completed claims form to this email'}
              type="input"
              required={true}
              isInvalid={errors?.claimsEmail}
            >
              <Input
                placeholder="Enter Email"
                name="claimsEmail"
                defaultValue={currentUser?.shopData?.userEmail}
                errorBorderColor="red"
                ref={register({ required: 'Email is required' })}
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.claimsEmail?.message}
              </FormErrorMessage>
            </FormElement>
          </FormSection>

          {/*
           *
           * Vehicle information Box
           *
           */}
          <FormSectionTitle
            title="Vehicle, Transmission, and Problem Information"
            subtitle="Please fill in any information you have."
          />
          <FormSection title="Vehicle Information" bgColor="blue">
            <FormElement
              id="year"
              type="input"
              label="Year:"
              required={true}
              isInvalid={errors?.year}
            >
              <Input
                type="number"
                placeholder='Four Numbers, example "1995" or "2001"'
                errorBorderColor="red"
                ref={register({
                  required: 'Year is required',
                  min: { value: 1941, message: '4 digits, later than 1940' },
                })}
                name="year"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.year?.message}
              </FormErrorMessage>
            </FormElement>

            <FormElement
              id="make"
              label="Make:"
              required={true}
              type="input"
              isInvalid={errors?.make}
            >
              <Input
                placeholder='Manufacturer, example "Ford" or "BMW"'
                errorBorderColor="red"
                ref={register({ required: 'Make is required' })}
                name="make"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.make?.message}
              </FormErrorMessage>
            </FormElement>

            <FormElement
              id="model"
              label="Model:"
              required={true}
              type="input"
              isInvalid={errors?.model}
            >
              <Input
                placeholder='Example:  "Probe" or "740i"'
                errorBorderColor="red"
                ref={register({ required: 'Model is required' })}
                name="model"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.model?.message}
              </FormErrorMessage>
            </FormElement>

            <FormElement
                id="trans"
                label="Transmission Model:"
                type="input"
            >
              <Input
                  placeholder='Example:  "CD4E" or "5HP-30"'
                  ref={register}
                  errorBorderColor="red"
                  name="trans"
              />
            </FormElement>
            <FormElement
                id="engine"
                label="Engine model or Displacement:"
                type="input"
            >
              <Input
                  placeholder='Example:  "5.7L" or "V6"'
                  ref={register}
                  errorBorderColor="red"
                  name="engine"
              />
            </FormElement>
            <FormElement
              id="mileage"
              label="Mileage:"
              type="input"
              isInvalid={errors?.mileage}
            >
              <Input
                placeholder="Enter full mileage number"
                errorBorderColor="red"
                ref={register}
                name="mileage"
                type="number"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.mileage?.message}
              </FormErrorMessage>
            </FormElement>
          </FormSection>

          {/*
           *
           * Problem details
           *
           */}
          <FormSection title="Problem Details" bgColor="blue">
            <FormElement
              id="comment"
              required={true}
              label="Please explain your problem:"
              sx={{ label: { mt: 3 } }}
              isInvalid={errors?.comment}
            >
              <Textarea
                placeholder="Please describe the issues you have discovered."
                ref={register({ required: 'Comment is required' })}
                height="204px"
                errorBorderColor="red"
                name="comment"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.comment?.message}
              </FormErrorMessage>
            </FormElement>
            <FormElement
              id="idleVoltage"
              label="What is the System Voltage?"
              type="input"
            >
              <Input
                placeholder="System voltage"
                ref={register}
                name="idleVoltage"
              />
            </FormElement>
            <FormElement
              id="idlePresure"
              label="What is the line pressure at idle?"
              type="input"
            >
              <Input
                placeholder="Line pressure at idle"
                ref={register}
                name="idlePresure"
              />
            </FormElement>
            <FormElement
                id="fuelPressureIdle"
                label="What is the fuel pressure in PSI with the key on and engine off?"
                type="input"
            >
              <Input placeholder="Fuel pressure at idle"
                     ref={register}
                     name="fuelPresure"/>
            </FormElement>
            <FormElement
              id="stallPresure"
              label="What is the line pressure at stall?"
              type="input"
            >
              <Input
                placeholder="Line pressure at stall"
                ref={register}
                name="stallPresure"
              />
            </FormElement>
            <FormElement
                id="idleVacuum"
                label="What is the vacuum at idle while using an analog gauge?"
                type="input"
            >
              <Input
                  placeholder="Vacuum at idle using analog gauge"
                  ref={register}
                  name="idleVacuum"
              />
            </FormElement>
          </FormSection>
          {/*
           *
           * Codes
           *
           */}
          <FormSection title="codes" bgColor="blue">
            <FormElement
              id="probstring"
              label="What seems to be the problem?"
              isInvalid={errors?.problem}
            >
              <RadioGroup defaultValue="scanner" name={'probstring'}>
                <VStack align="flex-start" spacing={4}>
                  <Radio
                    value="scanner"
                    defaultChecked
                    name="probstring"
                    ref={register}
                  >
                    Scanner obtained codes (list codes below)
                  </Radio>
                  <Radio value="manual" name="probstring" ref={register}>
                    Manually Obtained Codes (list codes below)
                  </Radio>
                  <Radio value="not-attempted" name="probstring" ref={register}>
                    No Code Retrieval was attempted
                  </Radio>
                  <Radio
                    value="no-codes-stored"
                    name="probstring"
                    ref={register}
                  >
                    Code retrieval indicated "No Codes Stored"
                  </Radio>
                  <Radio value="not-done" name="probstring" ref={register}>
                    Code retrieval was attempted but could not be done
                  </Radio>
                </VStack>
              </RadioGroup>
            </FormElement>
            {/* TODO: CODES! */}
            {/* TODO: numeric/string values! */}
            <FormElement
              id="codes"
              label="Please enter codes if any:"
              mt={10}
              sx={{ label: { mt: 4 } }}
            >
              {renderCodesUI()}
              <Box
                as="button"
                className="mt-4 text-sm font-bold uppercase transition duration-200 ease-in-out cursor-pointer text-lightBlue hover:text-darkBlue"
                onClick={onAddCodeInput}
              >
                + add another code
              </Box>
            </FormElement>
          </FormSection>

          {/*
           *
           * Request a tech
           *
           */}

          <FormSection title="Request a Technician" bgColor="blue">
            <FormElement
              type="input"
              id="atsgtechreq"
              label="Specific ATSG Technician Requested:"
            >
              <Select
                placeholder="Any ATSG Technician"
                layerStyle="input"
                name="atsgtechreq"
                ref={register}
              >
                {techniciansAvailable &&
                  techniciansAvailable.map((tech, i) => (
                    <option key={i} value={tech.name}>
                      {tech.name.toUpperCase()}
                    </option>
                  ))}
              </Select>
            </FormElement>
            <FormElement
              id="techAvailability"
              label="If this technician is not available:"
            >
              <RadioGroup defaultValue="any" name="techAvailability">
                <VStack align="flex-start" spacing={4}>
                  <Radio value="wait" name="techAvailability" ref={register}>
                    I will wait (May cause delayed response time)
                  </Radio>
                  <Radio
                    value="any"
                    name="techAvailability"
                    ref={register}
                    defaultChecked
                  >
                    Please send me to any ATSG Technician
                  </Radio>
                </VStack>
              </RadioGroup>
            </FormElement>
          </FormSection>
          <Flex
            align={{ base: 'flex-end', sm: 'flex-end' }}
            flexDirection="column"
          >
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              spacing={5}
              sx={{ button: { width: 200 } }}
            >
              <Button
                variant="outlineDark"
                onClick={() => {
                  setCodes(initialCodes)
                  reset({
                    deepNest: {
                      probstring: 'manual',
                    },
                  })
                }}
              >
                Reset
              </Button>
              <Button
                variant="lightBlue"
                type="submit"
                disabled={formStatus === 'submitting'}
              >
                Submit{formStatus === 'submitting' && 'ting'}
              </Button>
            </Stack>
            <Stack paddingBlockStart={3}>
              {/* TODO: Improved Success Alert! */}
              {formStatus === 'submitted' && (
                <Alert status="success">
                  <AlertIcon />
                  Call request successfully submitted!
                </Alert>
              )}

              {error && (
                <Alert status="error">
                  <AlertIcon />
                  An error happened! {error}
                </Alert>
              )}
            </Stack>
          </Flex>
        </form>
      </Box>
      <AverageCallInfoModal
        isOpen={!!averageCallInfo}
        onClose={() => setAverageCallInfo(null)}
        info={averageCallInfo}
      />
    </Container>
  )
}

export default RecyclerTechCallScreen
